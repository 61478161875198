import React, { useState, useEffect } from 'react';
import axios from 'axios';

const DynamicForm = () => {
  const [items, setItems] = useState([{ id: null, label: '', pie_size: '', possible_to_win: '1' }]);
  const [deletedItems, setDeletedItems] = useState([]); // Track deleted items
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

    // Fetch existing data from the database when the component loads
    useEffect(() => {
      console.log("Server URL: ", SERVER_URL+'/api/items');

        const fetchItems = async () => {
            try {
              const response = await axios.get(SERVER_URL+'/api/items');  
              // http://localhost:5588/api/items for development 
              // https://bcdademo.online/api/items in production with SSL

//            const response = await axios.get('https://bcdademo.online/api/items');  
            //const response = await axios.get('http://localhost:5588/api/items');  // change to https://bcdademo.online/api/items in production with SSL
            const fetchedItems = response.data;

            // If there are no saved items, keep an empty form field for the user
            if (fetchedItems.length > 0) {
                setItems(fetchedItems);
            }
            } catch (error) {
            console.error('Error fetching data:', error);
            }
        };

        fetchItems();
    }, []);
    
  // Handle input change
  const handleInputChange = (index, event) => {
    const values = [...items];
    values[index][event.target.name] = event.target.value;
    setItems(values);
  };

  // Add new item
  const handleAddItem = () => {
    setItems([...items, { id:null, label: '', pie_size: '', possible_to_win: '1' }]);
  };

    // Remove item and track it for deletion if it has an ID
    const handleRemoveItem = (index) => {
        const values = [...items];
        const itemToRemove = values[index];
    
        // If the item has an id, we track it for deletion
        if (itemToRemove.id) {
          setDeletedItems([...deletedItems, itemToRemove.id]);
        }
    
        values.splice(index, 1);
        setItems(values);
    };
    

  // Handle form submission (saving data)
  const handleSave = async () => {
    // Check if any item has an empty label
    const hasEmptyName = items.some(item => !item.label.trim());
    const hasZeroValue = items.some(item => item.pie_size === '0' || item.pie_size === 0);

    if (hasEmptyName) {
        alert('Item Name cannot be blank.');
        return; // Exit the function if validation fails
    }

    if (hasZeroValue) {
        alert('Pie size cannot be 0.');
        return; // Exit the function if validation fails
    }

    try {
      //const response = await axios.post('https://bcdademo.online/api/save', {
      //const response = await axios.post('http://localhost:5588/api/save', {   // change to https://bcdademo.online/api/save in production with SSL
        console.log("Server URL: ", SERVER_URL+'/api/save');
        const response = await axios.post(SERVER_URL+'/api/save', {   
            // http://localhost:5588/api/items for development 
            // https://bcdademo.online/api/items in production with SSL

        items: items,
        deletedIds: deletedItems
      });
      alert(response.data.message);
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };
  
  return (
    <div>
      <h2>Lucky Draw Config Page</h2>
      {items.map((item, index) => (
        <div key={index} style={{ marginBottom: '10px' }}>
          <input
            type="text"
            name="label"
            placeholder="Prize"
            value={item.label || ""}
            autoComplete="off"
            onChange={(event) => handleInputChange(index, event)}
          />&nbsp;&nbsp;&nbsp;
          <input
            type="number"
            name="pie_size"
            placeholder="Slice size"
            value={item.pie_size || 1}
            min="0"
            step="1"
            max="100"
            autoComplete="off"
            onChange={(event) => handleInputChange(index, event)}
          />%&nbsp;&nbsp;&nbsp;
          <select
            name="possible_to_win"
            placeholder="Possible to win?"
            onChange={(event) => handleInputChange(index, event)}
          >
          <option value="1" selected={item.possible_to_win===1}>Yes</option>
          <option value="0" selected={item.possible_to_win===0}>No</option>
          </select>
          &nbsp;&nbsp;&nbsp;
          <button type="button" onClick={() => handleRemoveItem(index)}>
            Delete
          </button>
        </div>
      ))}
      <button type="button" onClick={handleAddItem}>
        Add More
      </button>
      <br />
      <button type="button" onClick={handleSave} style={{ marginTop: '10px' }}>
        Save
      </button>
    </div>
  );
};

export default DynamicForm;
