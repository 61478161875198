import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import DynamicForm from './DynamicForm';
import Wheel from './Wheel';
import Circle1 from './circle1';



//import ReChart from './ReChart';
//import VivoChart from './VivoChart';

const App = () => {
  return (
    <Router>
      <div>
{/*
        <nav>
          <ul>
            <li>
              <Link to="/">Wheel (google-chart)</Link>
            </li>
            <li>
              <Link to="/circle">Circle</Link>
            </li>
            <li>
              <Link to="/config">Admin setup</Link>
            </li>
          </ul>
        </nav>
*/}
        <Routes>
          <Route path="/config" element={<DynamicForm />} />
          <Route path="/circle" element={<Circle1 />} />
          <Route path="/" element={<Wheel />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
