// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Basic container styling */
.image-container {
    display: flex;
    justify-content: center;  /* Center horizontally */
    align-items: center;      /* Center vertically */
    height: 100vh;            /* Full viewport height */
    width: 100vw;             /* Full viewport width */
}

/* Image styling */
.image-container img {
    object-fit: contain;      /* Maintain the image aspect ratio */
    border-radius: 0%;       /* Ensure the image stays circular */
    height: 100vh;            /* Full viewport height */
    width: 100vw;             /* Full viewport width */
}

/* Portrait mode: When width is less than height */
@media (max-aspect-ratio: 1/1) {
    .image-container img {
        width: 80vw;          /* 80% of the viewport width */
        height: auto;         /* Maintain aspect ratio */
    }
}

/* Landscape mode: When height is less than width */
@media (min-aspect-ratio: 1/1) {
    .image-container img {
        height: 60vh;         /* 60% of the viewport height */
        width: auto;          /* Maintain aspect ratio */
    }
}
`, "",{"version":3,"sources":["webpack://./src/circle.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;IACI,aAAa;IACb,uBAAuB,GAAG,wBAAwB;IAClD,mBAAmB,OAAO,sBAAsB;IAChD,aAAa,aAAa,yBAAyB;IACnD,YAAY,cAAc,wBAAwB;AACtD;;AAEA,kBAAkB;AAClB;IACI,mBAAmB,OAAO,oCAAoC;IAC9D,iBAAiB,QAAQ,oCAAoC;IAC7D,aAAa,aAAa,yBAAyB;IACnD,YAAY,cAAc,wBAAwB;AACtD;;AAEA,kDAAkD;AAClD;IACI;QACI,WAAW,WAAW,8BAA8B;QACpD,YAAY,UAAU,0BAA0B;IACpD;AACJ;;AAEA,mDAAmD;AACnD;IACI;QACI,YAAY,UAAU,+BAA+B;QACrD,WAAW,WAAW,0BAA0B;IACpD;AACJ","sourcesContent":["/* Basic container styling */\r\n.image-container {\r\n    display: flex;\r\n    justify-content: center;  /* Center horizontally */\r\n    align-items: center;      /* Center vertically */\r\n    height: 100vh;            /* Full viewport height */\r\n    width: 100vw;             /* Full viewport width */\r\n}\r\n\r\n/* Image styling */\r\n.image-container img {\r\n    object-fit: contain;      /* Maintain the image aspect ratio */\r\n    border-radius: 0%;       /* Ensure the image stays circular */\r\n    height: 100vh;            /* Full viewport height */\r\n    width: 100vw;             /* Full viewport width */\r\n}\r\n\r\n/* Portrait mode: When width is less than height */\r\n@media (max-aspect-ratio: 1/1) {\r\n    .image-container img {\r\n        width: 80vw;          /* 80% of the viewport width */\r\n        height: auto;         /* Maintain aspect ratio */\r\n    }\r\n}\r\n\r\n/* Landscape mode: When height is less than width */\r\n@media (min-aspect-ratio: 1/1) {\r\n    .image-container img {\r\n        height: 60vh;         /* 60% of the viewport height */\r\n        width: auto;          /* Maintain aspect ratio */\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
