import React from 'react';
import './circle.css';


function Circle1() {
  return (
        <div className="image-container">
            <img src="circle.png" alt="Circle Image" />
        </div>
  );
}

export default Circle1;
