import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Chart } from "react-google-charts";
import './Wheel.css'; // Import the CSS file
import audioFile1 from './Spinning Wheel Sound Effect (5s win).mp3';
import audioFile2 from './Spinning Wheel Sound Effect (5s lose).mp3';


const Wheel = () => {
    
    const [prizes, setPrizes] = useState([{ id: null, label: '', pie_size: '', possible_to_win: '1' }]);
    const [chartData, setChartData] = useState([["Prize","size"]]);    
//    const [tmpChart, setTmpChart] = useState([["Prize","size"]]);   
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;

    var counter = 0;
    // Fetch existing data from the database when the component loads


    const [lastClickTime, setLastClickTime] = useState(Date.now());
    const audioFileWin = new Audio(audioFile1);
    const audioFileLose = new Audio(audioFile2);

    const handleClick = () => {
      // Update the last click time
      setLastClickTime(Date.now());
      // You can also add other click handling logic here
    };
  
    useEffect(() => {
      const timer = setInterval(() => {
        // Check if 15 seconds have passed since the last click
        if (Date.now() - lastClickTime >= 15000) {
//          window.location.reload();
          audioFileWin.load();
          audioFileLose.load();  
        }
      }, 1000); // Check every second
  
      return () => clearInterval(timer); // Cleanup on component unmount
    }, [lastClickTime]); // Depend on lastClickTime

    

    useEffect(() => {
        // Preload the audio file
//        const audioFileWin = new Audio('./Spinning Wheel Sound Effect (5s win).mp3');
//        const audioFileLose = new Audio('./Spinning Wheel Sound Effect (5s lose).mp3');
        audioFileWin.load();
        audioFileLose.load();
        setAudioWin(audioFileWin);
        setAudioLose(audioFileLose);

    
        console.log("Server URL: ", SERVER_URL+'/api/items');

        console.log("/////Counter = "+counter++);
        const fetchItems = async () => {
            try {
                var tmpChart=[["Prize","size"]];  // clear the data before populating
                var tmpPrizes=[];  //[{ id: null, label: '', pie_size: '', possible_to_win: '1' }];

                const response = await axios.get(SERVER_URL+'/api/items');  
                // http://localhost:5588/api/items for development 
                // https://bcdademo.online/api/items in production with SSL
                const fetchedItems = response.data;

                console.log(">>>> Counter = "+counter++);

                // If there are no saved items, keep an empty form field for the user
                if (fetchedItems.length > 0) {

                    var total_size = 0;
                    fetchedItems.forEach(item => {
                        //console.log("Counter = "+counter++);

                        const { id, label, pie_size, possible_to_win } = item;
                        total_size += pie_size;
                        //console.log("label: "+label);
                        //console.log("pie_size: "+pie_size);
                        
                    });

                    // then we calc the ratio and apply the ratio to pie slices to make total to be 100%
                    var ratio = 100 / total_size;

                    fetchedItems.forEach(item => {
                      //console.log("Counter = "+counter++);

                      const { id, label, pie_size, possible_to_win } = item;
                      //console.log("label: "+label);
                      //console.log("pie_size: "+pie_size);
                      
                      tmpChart.push([label, pie_size*ratio]);
                      //console.log("tmpChart = "+ tmpChart);
                      tmpPrizes.push({ id: id, label: label, pie_size: pie_size*ratio, possible_to_win: possible_to_win });
                    });

                    console.log("Prizes: "+fetchedItems);
                }
                setChartData(tmpChart);
                setPrizes(tmpPrizes);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchItems();
        console.log(">>>> chartData: "+chartData);
        console.log(">>>> Prizes: ", prizes);

    }, []);
    
    
    function calculatePrize(finalRotation) {
      let finalAngle = finalRotation % 360; // Get angle between 0 and 360
      let cumulativeAngle = 0;
      let prevAngle = 0;
    console.log(">>>>>   finalAngle = "+finalAngle);
      // Iterate over prizes and determine the one that matches the final rotation angle
    
      const reversedPrizes = [...prizes].reverse();
    console.log("reversedPrizes = "+prizes);
      for (const prize of reversedPrizes) {
    console.log("Prize = "+ prize.label + ", angle = "+Math.floor((prize.pie_size/100)*360));
        cumulativeAngle += Math.floor((prize.pie_size/100)*360);
    console.log("cumulativeAngle = "+cumulativeAngle);
        if ( cumulativeAngle >= finalAngle ) {  // found the prize!
    console.log(" +++++++++++++++++++ Prize = "+prize.label);
          if ( prize.possible_to_win > 0) {
            if (cumulativeAngle - finalAngle < 3 || finalAngle - prevAngle < 3)
              return "edge";
            else 
              return prize.label;
          } else 
            return "out of stock";
        }
        prevAngle = cumulativeAngle;
      }
      return "Thank you";
    }
    
    const [audioWin, setAudioWin] = useState(null);
    const [audioLose, setAudioLose] = useState(null);

    const playAudioWin = () => {
      if (audioWin) {
        audioWin.play();
      }
    };

    const playAudioLose = () => {
      if (audioLose) {
        audioLose.play();
      }
    };

      const [selectedPrize, setSelectedPrize] = useState(null);
      const [isSpinning, setIsSpinning] = useState(false);
      const [rotationAngle, setRotationAngle] = useState(0); // Store the current rotation angle
    //  const [newRotation, setNewRotation] = useState(0); // Store the current rotation angle
      const [spinClass, setSpinClass] = useState(""); // Add state to control spin animation
    
    
      const options = {
        pieHole: 0,
        pieSliceText: "label",
        pieStartAngle: 0,
        tooltip: { trigger: "none" },
        legend: "none",
        slices: [
          { offset: 0, color: "#0f0d58" }, // Hand sanitiser
          { offset: 0, color: "#f4b400" }, // Mercedes Benz
          { offset: 0, color: "#c0c0c0" }, // Thank you for playing
          { offset: 0, color: "#4285f4" }, // Tesla
          { offset: 0, color: "#0f9d58" }, // Phone stand
          { offset: 0, color: "#c0c0c0" }, // Thank you for playing
        ],
        chartArea: {
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
        },
      
        sliceVisibilityThreshold: 0,
    
        backgroundColor: "transparent",
        backgroundColor: "green",
      };
    
      const spinWheel = () => {
        setLastClickTime(Date.now());

        setIsSpinning(true);
    
        let newRo1 = 0;
        let prize = "out of stock";
    
        while (prize === "out of stock" || prize === "edge") {  // respin until it does NOT lands on "out of stock" prize or  on the dge
          newRo1 = Math.floor(Math.random() * 360) + 1800; // Random stop angle plus 5 full spins
          //const newRo1 = 44; // Random stop angle plus 5 full spins
    //      console.log("new random = "+(newRo1));
    //      console.log("new RotationAngle = "+(newRo1+rotationAngle));
        //  setNewRotation(newRo1+newRotation); 
    
          prize = calculatePrize(newRo1+rotationAngle);
          if (prize === "out of stock")
            console.log(">>>>>>>>>>>  out of stock");
          else if (prize === "edge")  
            console.log(">>>>>>>>>>>  edge");
          else
            break;
        }
    
        setRotationAngle(newRo1+rotationAngle); // Store the new angle for spin
        if (prize === "Thank you")
          playAudioLose();
        else
          playAudioWin();
        setSpinClass("spin"); // Trigger the spin animation
        setTimeout(() => {
          setSelectedPrize(prize);
          setIsSpinning(false);
          setSpinClass(""); // Reset the spin animation class after spinning
        }, 6000); // 6 seconds delay for spin animation
    
        
      };
    
      return (
        <div class="centercontainer">
            <h1>Lucky Spin</h1>
          <br />
          <div className="wheel-wrapper">
            <img className="needle" src="luckywheelneedle.png" />
            <div
              className={`wheel ${spinClass}`}
              style={{
                transform: `rotate(${rotationAngle}deg)`, // Rotate based on the random angle
              }}
            >
                <img className="wheel" src="Wheel2a2.png" />
                {/*
                
                <Chart
                  chartType="PieChart"
                  width={"50vw"}
                  height={"50vh"}
                  data={chartData}
                  options={options}
                />
                */
                }
            </div>
            <img className="wheel outercircle" src="outer_ring.png"/>
          </div>
    
          <br />
          <button
            class="button-19" 
            role="button"
            onClick={spinWheel}
            disabled={isSpinning}
            style={{
              marginTop: "20px",
              padding: "10px 20px",
              fontSize: "3vh",
              cursor: isSpinning ? "not-allowed" : "pointer",
              margin:"0 auto",
              display: "block",
            }}
          > 
            {isSpinning ? "Spinning..." : "GO"
            }
          </button>
          {selectedPrize && !isSpinning && (
            <h2 style={{ color: "#333" }}>
              {selectedPrize!=="Thank you"?
              `Congratz, you won a ${selectedPrize.trim()}!`: "Thank you for playing, better luck next time!" 
              }
            </h2>
          )}
          {(!selectedPrize || isSpinning) && (
            <h2 style={{ color: "#333" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
 
            </h2>
          )}
        </div>
      );
};

export default Wheel;

